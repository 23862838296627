.assets {
  padding: 85px 0 59px;
  overflow: hidden;
}

@media (max-width: 1120px) {
  .assets {
    padding: 28px 0 23px;
  }
}

.assets__info {
  display: flex;
  justify-content: space-between;
}

.assets__h2 {
  margin: 0 0 0;
}

@media (max-width: 1340px) {
  .assets__h2 {
    font-size: 75px;
    max-width: 946px;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .assets__h2 {
    font-size: 75px;
  }
}

@media (max-width: 768px) {
  .assets__h2 {
    font-size: 30px !important;
    margin: 0 auto 16px !important;
  }
}

.assets__describe {
  font-family: "MacPawFixelDisplay-Regular";
  font-size: 22px;
  font-style: normal;
  margin: 0;
}

@media (max-width: 768px) {
  .assets__describe {
    line-height: normal;
  }
}

@media (max-width: 768px) {
  .assets__describe {
    font-size: 18px;
    margin: 0 auto 25px;
    width: 100%;
  }
}

.assets__link-box {
  border-radius: 20px;
  padding: 18px 20px 18px 24px;
  min-width: 379px;
}

@media (max-width: 768px) {
  .assets__link-box {
    min-width: 317px;
  }
}

.assets__link_grow {
  background: var(--grey) url(/img/graph-2.png) left bottom no-repeat;
}

.assets__link_down {
  background: var(--grey) url(/img/graph-1.png) left bottom no-repeat;
}

.assets__link-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 73px;
}

.assets__link-cur {
  display: flex;
  align-items: center;
}

.assets__link-cur-info {
  margin-left: 15px;
}

.assets__link-cur-info span {
  display: block;
  color: var(--dark);
}

.assets__link-cur-info span:first-child {
  font-family: "MacPawFixelDisplay-Bold";
  font-style: normal;
  margin-bottom: 4px;
}

.assets__link-cur-info span:last-child {
  font-style: normal;
}

.assets__link-price {
  font-family: "MacPawFixelDisplay-Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.assets__link_grow .assets__link-price {
  color: var(--green);
}

.assets__link_down .assets__link-price {
  color: var(--red);
}

.assets__link {
  background-color: var(--dark);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 38px;
  height: 38px;
  float: right;
}

@media (max-width: 1120px) {
  .assets__link {
    transform: translateY(-35px);
  }
}

.home_section .assets__info-middle {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 32px;
}

@media (max-width: 1120px) {
  .home_section .assets__info-middle {
    display: block;
  }
}

@media (max-width: 1120px) {
  .assets__info {
    display: block;
  }
}

.assets__info-bottom {
  display: flex;
}

@media (max-width: 1120px) {
  .assets__info-bottom {
    flex-direction: column;
    /* text-align: center; */
    justify-content: center;
    width: 100%;
  }
}

.assets__info-bottom-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 1120px) {
  .home_section .assets__info-bottom-cards {
    display: none;
    margin-top: -33px;
  }
  .home_section .assets__info-middle:not(.assets__info-top) {
    display: none;
    margin-top: -33px;
  }
  .home_section .assets__top {
    text-align: center;
    margin-bottom: 40px;
  }
}
.home_section .assets__top {
  margin-bottom: 38px;
}
.assets__info-bottom-info {
  min-width: 379px;
  max-width: 379px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  max-height: 193px;
}

@media (max-width: 1120px) {
  .assets__info-bottom-info {
    order: 1;
    min-width: 344px;
    margin: 0 auto;
    padding: 0;
  }
}

.assets__info-bottom-text {
  font-family: "MacPawFixelDisplay-Regular";
  font-size: 20px;
  line-height: normal;
  flex: 1;
}

@media (max-width: 1120px) {
  .assets__info-bottom-text {
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin: 0 auto 14px;
    position: relative;
  }
}

.assets__info-bottom-btn {
  min-width: 242px;
  max-width: 242px;
}

@media (max-width: 1340px) {
  .home_section .assets__link-box:nth-child(n) {
    max-width: 400px;
    margin: 0 auto 0px;
    box-shadow: 0 0 15px 10px rgba(25, 24, 24, 0.09);
  }
}

@media (max-width: 1120px) {
  .home_section .assets__link-box:nth-child(1n) {
    transform: translateX(25px);
  }
  .home_section .assets__link-box:nth-child(2n) {
    transform: translateX(-25px);
  }
}

@media (max-width: 768px) {
  .home_section .assets__link-box:nth-child(1n) {
    transform: translateX(10px);
  }

  .home_section .assets__link-box:nth-child(2n) {
    transform: translateX(-10px);
  }
}

@media (max-width: 1120px) {
  .home_section .assets__info-bottom-cards .assets__link-box:first-child {
    transform: translateX(-25px);
  }

  .home_section .assets__info-bottom-cards .assets__link-box:nth-child(2) {
    transform: translateX(25px);
  }
}

@media (max-width: 768px) {
  .home_section .assets__info-bottom-cards .assets__link-box:first-child {
    transform: translateX(-10px);
  }

  .home_section .assets__info-bottom-cards .assets__link-box:nth-child(2) {
    transform: translateX(10px);
  }
}

.home_section .showAll {
  display: block !important;
}
