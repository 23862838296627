@import "../../common/variables"
@import "../../common/mixins"

.legal-content
    & strong
        font-family: 'MacPawFixelDisplay-Medium'
    & a
        color: var(--primary-purple)
    & p:not(.last_updated)
        font-size: 20px
        font-family: 'MacPawFixelDisplay-Regular'
        font-weight: 400
        line-height: normal
        margin-block: 26px
        +max-w(768)
            font-size: 16px
    & ol>li, ul>li
        padding-bottom: 22px
        & h6
            margin-top: 21px
        & b
            display: block
            font-family: 'MacPawFixelDisplay-Medium'
            margin-bottom: 16px
    & h6
        font-size: 18px
        font-family: 'MacPawFixelDisplay-Bold'
        +max-w(768)
            font-size: 16px
        &:has(+ ul, + ol, + h6)
            margin-bottom: 21px !important
    .dotted
        list-style-type: disc
        padding-left: 21px
        list-style-position: outside
        & > li
            padding-left: 10px
            &::marker
                color: red
    .ab
        margin-top: 16px
        padding-left: 21px
        list-style-type: lower-alpha
        list-style-position: outside
        & > li
            padding-left: 10px
            & > ul, & > ol
                list-style-position: outside
                list-style-type: lower-roman
                padding: 0 0 0 31px
                & > li
                    padding-left: 10px
    .legal__inner-list
        & li
            padding-top: 16px
            padding-bottom: 0
    .last_updated
        font-size: 16px
        font-family: "MacPawFixelDisplay-Medium"
        line-height: normal
        color: var(--dark-3)
        margin-bottom: 40px
    .legal-nav__link
        font-size: 20px
        +max-w(768)
            font-size: 18px
