@import "../../common/variables"
@import "../../common/mixins"

.blogs
    &__grid
        display: grid
        grid-template-columns: repeat(2,1fr)
        column-gap: 30px
        row-gap: 35px
        +max-w($mobile_lg)
            grid-template-columns: 1fr
            gap: 16px
    &__pagination
        display: flex
        gap: 10px
        margin: 35px auto
        width: fit-content
        &-item
            cursor: pointer
            user-select: none
            display: flex
            align-items: center
            justify-content: center
            font-size: 22px
            font-family: "MacPawFixelDisplay-Regular"
            height: 50px
            width: 50px
            border: 1px solid #9F9F9F
            color: #9F9F9F
            border-radius: 8px
        & .active
            border: 1px solid #694D97
            color: #694D97
            font-family: "MacPawFixelDisplay-Medium"
        & .arrow-left:hover, & .arrow-right:hover
            border: 1px solid #694D97
            & > svg > *
                stroke: #694D97

.blog-card
    width: 570px
    padding: 35px
    background: white url(/img/blog-paper-bg.svg)
    background-repeat: no-repeat
    background-position: bottom right
    border-radius: 20px
    display: flex
    flex-direction: column
    gap: 35px
    +max-w($mobile_lg)
        width: 100%
        padding: 30px
        gap: 35px
    &__header
        & > img
            max-height: 250px
    &__title
        font-size: clamp(20px, 4vw, 40px)
        font-family: "MacPawFixelDisplay-Bold"
        line-height: normal
    &__min-content
        font-family: "MacPawFixelDisplay-Regular"
        font-size: clamp(18px, 4vw, 22px)
        line-height: normal
        flex: 1
    &__bottom
        display: flex
        justify-content: space-between
        align-items: center
        & > span
            font-size: 16px
            color: #9F9F9F

.blog-header
    margin: 35px 0
    display: flex
    flex-direction: column
    gap: 35px
    align-items: center
    &__chip
        background-color: black
        padding: 21px 35px
        border-radius: 40px
        font-size: 18px
        font-family: "MacPawFixelDisplay-Bold"
        width: fit-content
        color: white
    &__title
        font-size: clamp(20px, 4vw, 40px)
        font-family: "MacPawFixelDisplay-Bold"
        line-height: normal
        text-transform: uppercase
        +max-w($mobile_lg)
            text-align: center

.blog-content

    line-height: normal
    +max-w($mobile_lg)
        margin-top: 20px
    & .mobile-cover
        display: none
        +max-w($mobile_lg)
            display: block
    &__cover
        background: #01003A
        min-height: 550px
        display: flex
        align-items: center
        flex-direction: column
        position: relative
        border-radius: 20px
        overflow: hidden
        gap: 0 !important
        +max-w($mobile_lg)
            display: none
        &-cards
            position: absolute
            bottom: 0
        &-bg
            width: 100%
            height: 100%
            position: absolute
            object-fit: cover
        &-main
            max-width: 684px
            height: 80px
            z-index: 100
            margin-top: 120px
            +max-w($mobile_lg)
                margin-top: 20px
                width: 100%
                object-fit: cover
                height: auto
        & > span
            font-family: "MacPawFixelDisplay-Bold"
            margin-top: 55px !important
            display: block
            font-size: clamp(20px, 4vw, 40px)
            text-transform: uppercase
            color: white !important
            z-index: 100
            min-width: auto !important
            max-width: 440px
            width: 440px
            text-align: center
            +max-w($mobile_lg)
                width: 100%

    &__content
        & > *
            font-size: clamp(18px, 4vw, 22px) !important
            font-family: "MacPawFixelDisplay-Regular"
        & p:has(+ul) + ul
            margin-bottom: 16px
            list-style-type: disc
            margin-left: 30px
        & ol:has(+ul) + ul
            list-style-type: disc
            margin-left: 30px
            margin: 16px 0 16px 30px
        & .blog-bold-list
            & li:has(>ul), & li:has(>p)
                font-family: "MacPawFixelDisplay-Bold"
                & > ul
                    font-family: "MacPawFixelDisplay-Regular"
                    list-style-type: disc
                    padding-left: 30px
                & > p
                    padding-left: 30px
        & li>p
            margin-top: 16px
        & li>ul
            margin: 16px 0

    &__header
        background-color: white
        padding: 35px
        margin-bottom: 75px
        border-radius: 20px
        margin-top: 45px
        +max-w($mobile_lg)
            padding: 30px
            margin-bottom: 30px
            margin-top: 20px
        & .titlebar
            margin-top: 35px
            display: flex
            gap: 100px
            +max-w($mobile_lg)
                display: block
            & p
                font-size: clamp(20px, 4vw, 40px)
                font-family: "MacPawFixelDisplay-Bold"
                margin-bottom: 0
            & span
                font-size: 16px
                color: #9F9F9F
                display: block
                min-width: fit-content
                margin-top: 20px
    & strong
        display: block
        margin: 20px 0
        font-size: clamp(18px, 4vw, 22px)
        font-family: "MacPawFixelDisplay-Bold"

    & p
        margin-bottom: 20px
        font-size: clamp(18px, 4vw, 22px)
        font-family: "MacPawFixelDisplay-Regular"
.related-blogs
    margin-top: 75px
    & > div
        display: flex
        gap: 35px
        +max-w($mobile_lg)
            flex-direction: column
            gap: 16px

    & p
        font-size: 22px
        text-transform: uppercase
        font-family: "MacPawFixelDisplay-Bold"
        margin-bottom: 35px
